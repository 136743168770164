import { LegacyRef } from 'react'
import { Stack, Button } from '@mui/material'
import PaymentAmountMessage from './PaymentAmountMessage'
import CardTypeDisplay from './CardTypeDisplay'
import SurchargeDetails from './SurchargeDetails'
import SecurePayment from './SecurePayment'
import { DisplayCardType } from '../../../api/dto/PaymentConfigDto'

// +--------------------------------------------------+
//               Enums / Types Section
// +--------------------------------------------------+

export enum CardTypes {
  visa, mastercard, amex, none
}

export enum HostedFieldStatus {
  valid, invalid, none
}

enum HostedFieldStyle {
  border, icon, both
}

// Braintree related
export type FieldStatus = {
  name: HostedFieldStatus,
  card: HostedFieldStatus,
  expiry: HostedFieldStatus,
  cvv: HostedFieldStatus
}

export const defaultFieldStatuses = {
  name: HostedFieldStatus.none,
  card: HostedFieldStatus.none,
  expiry: HostedFieldStatus.none,
  cvv: HostedFieldStatus.none
}

// Cybersource related
export type FieldStatusCyberSource = {
  name: HostedFieldStatus,
  card: HostedFieldStatus,
  expiry: HostedFieldStatus,
  cvv: HostedFieldStatus
}

export const defaultFieldStatusesCyberSource = {
  name: HostedFieldStatus.none,
  card: HostedFieldStatus.none,
  expiry: HostedFieldStatus.none,
  cvv: HostedFieldStatus.none
}

// +--------------------------------------------------+
//                  Helper Functions
// +--------------------------------------------------+

// Determines the class to be used on a 
// hosted field container based on it's validity
// 
const DetermineFieldClass = (status: HostedFieldStatus | boolean, style: HostedFieldStyle = HostedFieldStyle.both) => {
  let textStatus = '';
  if (status === HostedFieldStatus.valid) textStatus = 'valid'
  else if (status === HostedFieldStatus.invalid) textStatus = 'invalid'
  else return ''

  if (style === HostedFieldStyle.both) return `${textStatus} ${textStatus}-icon`
  else if (style === HostedFieldStyle.border) return `${textStatus}`
  else if (style === HostedFieldStyle.icon) return `${textStatus}-icon`
}

// CardholderName
// -------------------------
// Shows the hosted field for entering the 
// card holders name, with associated field 
// label and failed validation message
//
const CardholderName = (props: { status: HostedFieldStatus }) => (
  <Stack id="stack-creditcard-name">
    <label id="cardholderNameLabel" htmlFor="name">Card holder name</label>
    {/* Hosted field for the card holders name will be injected into the div below */}
    <div id="cardholderName" className={`hosted-field ${DetermineFieldClass(props.status)}`} data-placeholder="Name on card" />
    {props.status === HostedFieldStatus.invalid && (
      <div id="cardholderName-invalid" className="invalid-feedback">
        Invalid card holder name.
      </div>
    )}
  </Stack >
)

// CardNumber
// -------------------------
// Shows the hosted field for entering the 
// credit card number, with associated field 
// label and failed validation message
//
const CardNumber = (props: { status: HostedFieldStatus, cardType: CardTypes, displayCardTypes?: DisplayCardType[], labels: Labels }) => (
  <Stack id="stack-creditcard-number">
    <label id="numberLabel" htmlFor="number">Card number</label>
    {/* Hosted field for the card number will be injected into the div below, based off the id */}
    <div id="number" className={`hosted-field ${DetermineFieldClass(props.status)}`} data-placeholder="Card Number" />
    {props.status === HostedFieldStatus.invalid && (
      <div id="number-invalid" className="invalid-feedback">
        {props.labels.cardNumberInvalid
          ? props.labels.cardNumberInvalid
          : "An invalid or un-support number was entered. Please check your number and try again."
        }
      </div>
    )}
    {(props.displayCardTypes && props.displayCardTypes?.length > 0) && (
      <CardTypeDisplay activeCardType={props.cardType} displayCardTypes={props.displayCardTypes} />
    )}
  </Stack>
)

// CardExpiry
// -------------------------
// Shows the hosted field for entering the 
// credit card expiry date, with associated field 
// label and failed validation message
//
const CardExpiry = (props: { status: HostedFieldStatus }) => (
  <Stack id="stack-creditcard-expiry">
    <label id="expirationDateLabel" htmlFor="expMonth">Expiry date</label>
    <div
      id="expirationDate"
      className={`hosted-field short ${DetermineFieldClass(props.status)}`}
      data-placeholder="Expiry (MM/YY)"
    />
    {props.status === HostedFieldStatus.invalid && (
      <div id="expirationDate-invalid" className="invalid-feedback">
        Your card's expiration date is invalid.
      </div>
    )}
  </Stack>
)

// CardCVV
// -------------------------
// Shows the hosted field for entering the 
// credit card CVV/CV2, with associated field 
// label and failed validation message
//
const CardCVV = (props: { status: HostedFieldStatus, cardType: CardTypes, domain: string }) => (
  <Stack id="stack-creditcard-cvv">
    <label id="cvvLabel" htmlFor="securityCode">Security number (CVV)</label>
    <Stack m="0" p="5" direction="row" alignItems="center">
      <div className={`hosted-field flex-row short ${DetermineFieldClass(props.status, HostedFieldStyle.border)}`}>
        <div
          id="cvv"
          data-placeholder="CVV"
          style={{ display: "block" }}
          className={DetermineFieldClass(props.status, HostedFieldStyle.icon)}
        />
        <div id="cvv-type-container">
          {props.cardType === CardTypes.amex
            ? <img className="creditcard-cvv-amex" src="/static/images/card-cvv-icon-amex.png" alt="Credit card icon showing cvv location for American Express" />
            : <img className="creditcard-cvv-default" src="/static/images/card-cvv-icon.png" alt="Credit card icon showing cvv location" />
          }
        </div>
      </div>

      <div id="div-creditcard-cvv-message">
        {props.cardType === CardTypes.amex
          ? 'The top 4 digits on the front of your card'
          : 'The last 3 digits on the back of your card'
        }
      </div>
    </Stack>
    {props.status === HostedFieldStatus.invalid && (
      <div id="cvv-invalid" className="invalid-feedback">
        Invalid CVV value.
      </div>
    )}
  </Stack>
)

// Pay now button
const PayButton = (props: { buttonTitle: string }) => (
  <Button id="submit" className="card-submit" disableElevation variant="contained" type="submit" fullWidth>
    {props.buttonTitle}
  </Button>
)

// Labels needed
// ---------------------------
type Labels = {
  cardExpiryDate?: string,
  cardHolderName?: string,
  cardHolderNamePlaceholder?: string,
  cardNumber?: string,
  cardNumberPlaceholder?: string,
  cardNumberInvalid?: string,
  cardSecurityCode?: string,
  cardSecurityCodePlaceholder?: string,
  cardPayButtonTitle?: string
}

// +--------------------------------------------------+
//                   Main Props
// +--------------------------------------------------+
export type PaymentMethodCreditCardProps = {
  fieldStatus: FieldStatus,
  cardType: CardTypes,
  amountTotal: number,
  currencySymbol: string,
  aboutToPayMessageEnabled: boolean,
  domain: string,
  displaySurcharge: boolean,
  displayCardTypes?: DisplayCardType[],
  creditCardRef: LegacyRef<HTMLDivElement> | undefined,
  labels: Labels
}

// +--------------------------------------------------+
//                   Main Component
// +--------------------------------------------------+
export default function PaymentMethodCreditCard(props: PaymentMethodCreditCardProps) {
  return (
    <div id="cardpayment" className="payment-section-div" ref={props.creditCardRef} >
      <div>
        <Stack>
          {props.aboutToPayMessageEnabled && <PaymentAmountMessage currencySymbol={props.currencySymbol} amount={props.amountTotal} />}
          <CardholderName status={props.fieldStatus.name} />
          <CardNumber status={props.fieldStatus.card} cardType={props.cardType} displayCardTypes={props.displayCardTypes} labels={props.labels} />
          <CardExpiry status={props.fieldStatus.expiry} />
          <CardCVV status={props.fieldStatus.cvv} cardType={props.cardType} domain={props.domain} />
          {props.displaySurcharge && <SurchargeDetails />}
          <PayButton buttonTitle={props.labels.cardPayButtonTitle ?? 'Pay now'} />
        </Stack>
      </div>

      <SecurePayment domain={props.domain} name="Card Pay" />
    </div>
  )
}


