import { HostedFieldsEvent, HostedFieldsHostedFieldsFieldName, HostedFieldsState } from "braintree-web/hosted-fields";
import { PaymentPayload } from "@illiondts/braintree/lib/mjs/data/PaymentPayload";
import { MyApp } from "../../../../context/AppContext";
import { SuccessReceiptDto } from "../../body/SuccessReceipt";
import { FailedReceiptDto } from "../../body/FailedReceipt";
import { PaymentProcessingDto } from "../../../../api/dto/PaymentProcessingDto";
import { ProcessingBackDropProps } from "../ProcessingBackDrop";
import { FieldStatus, HostedFieldStatus, CardTypes } from "../PaymentMethodCreditCard";

// +--------------------------------------------------+
//           BrainTree Credit Card Handlers
// +--------------------------------------------------+

// onSubmitHandler
// -------------------------
// Triggered when the "Pay now" button is clicked
// in the credit card payment option, this gives us a
// chance to check if any field values are empty/invalid
// and update the UI to reflect.
//
export const onSubmitHandler = (
  state: HostedFieldsState,
  setFieldStatus: React.Dispatch<React.SetStateAction<FieldStatus>>
) => {
  let key: HostedFieldsHostedFieldsFieldName;

  // Loops through the state of the hosted fields
  for (key in state.fields) {
    const field = state.fields[key];

    // If any hosted field is not valid then call the method below to update
    // the "fieldStatus" hooked used by the UI, this will cause the field 
    // invalid message to show and apply any associated 'invalid' css styles.
    if (!field.isValid) {
      updateFieldStatus(field.container.id, HostedFieldStatus.invalid, setFieldStatus);
    }
  }
}

// updateFieldStatus
// -------------------------
function updateFieldStatus(
  fieldId: string,
  fieldValue: HostedFieldStatus,
  setFieldStatus: React.Dispatch<React.SetStateAction<FieldStatus>>
) {
  // Determine which status field to update by it's id, we spread the "prevState"
  // as we need to create a new object but want to retain the previous
  // values for the fields that won't be updated.
  if (fieldId === "cardholderName")
    setFieldStatus(prevState => ({ ...prevState, name: fieldValue }));
  else if (fieldId === "number")
    setFieldStatus(prevState => ({ ...prevState, card: fieldValue }));
  else if (fieldId === "expirationDate")
    setFieldStatus(prevState => ({ ...prevState, expiry: fieldValue }));
  else if (fieldId === "cvv")
    setFieldStatus(prevState => ({ ...prevState, cvv: fieldValue }));
}

// onValidityChangeHandler
// -------------------------
export const onValidityChangeHandler = (
  event: HostedFieldsEvent,
  setFieldStatus: React.Dispatch<React.SetStateAction<FieldStatus>>
) => {
  const field = event.fields[event.emittedBy];
  if (!field.container) return;

  // Default
  let fieldStatus = HostedFieldStatus.none;

  // Map the status of the hosted field to: valid/invalid/none
  if (field.isValid) fieldStatus = HostedFieldStatus.valid
  else if (field.isPotentiallyValid) fieldStatus = HostedFieldStatus.none
  else fieldStatus = HostedFieldStatus.invalid

  // Call the method below to update the "fieldStatus" hooked used by the UI
  updateFieldStatus(field.container.id, fieldStatus, setFieldStatus);
}

// onCardTypeChangeHandler
// -------------------------
export const onCardTypeChangeHandler = (
  event: HostedFieldsEvent,
  setCardType: React.Dispatch<React.SetStateAction<CardTypes>>,
  setFieldStatus: React.Dispatch<React.SetStateAction<FieldStatus>>
) => {
  if (event.cards.length === 1) {
    // Card type change event, get the first card...
    const card = event.cards[0];
    // BrainTree lets us know the card type, so set it locally
    if (card.type === "visa") setCardType(CardTypes.visa);
    else if (card.type === "master-card") setCardType(CardTypes.mastercard);
    else if (card.type === "american-express") setCardType(CardTypes.amex);
    else {
      // Unknown card type
      setCardType(CardTypes.none);
      setFieldStatus(prevState => ({ ...prevState, card: HostedFieldStatus.invalid }));
    }
  } else {
    setCardType(CardTypes.none);
  }
}

// onCardTypeChangeHandler
// -------------------------
const updateFocusClasses = (
  mode: "add" | "remove",
  event: HostedFieldsEvent
) => {
  const divFocusClass = "hosted-field-focus";
  const labelFocusClass = "hosted-field-label-focus";

  const divElement = document.querySelector<HTMLElement>(`#${event.emittedBy}`);
  const labelElement = document.querySelector<HTMLElement>(`#${event.emittedBy}Label`);

  if (mode === "add") {
    divElement?.classList.add(divFocusClass);
    labelElement?.classList.add(labelFocusClass);
  } else {  // "Remove"
    divElement?.classList.remove(divFocusClass);
    labelElement?.classList.remove(labelFocusClass);
  }
}

// onFocusHandler
// -------------------------
export const onFocusHandler = (event: HostedFieldsEvent) => {
  updateFocusClasses("add", event);
}

// onBlurHandler
// -------------------------
export const onBlurHandler = (event: HostedFieldsEvent) => {
  updateFocusClasses("remove", event);
}

// +--------------------------------------------------+
//               General Payment Handlers
// +--------------------------------------------------+

export async function onPaymentRequestableHandler(
  method: string,
  payload: PaymentPayload,
  paymentAmount: number,
  paymentProcessingConfig: PaymentProcessingDto,
  deviceDataRef: React.MutableRefObject<string | undefined>,
  setProcessing: React.Dispatch<React.SetStateAction<ProcessingBackDropProps>>,
  setFailedReceipt: React.Dispatch<React.SetStateAction<FailedReceiptDto | undefined>>,
  setSuccessReceipt: React.Dispatch<React.SetStateAction<SuccessReceiptDto | undefined>>
) {

  // Register token
  let registerToken = false;
  if (method === "card" || method === "paypal") {
    registerToken = true;
  }

  // Device data
  const deviceData = deviceDataRef.current;

  var paymentComplete = false;

  // Should we show a delayed alternative payment processing message?
  if (paymentProcessingConfig.showAltDelayedMessage) {
    // Set up a timeout for changing the payment processing message
    // if it's taking longer then expected waiting on a payment result
    setTimeout(() => {
      // Has the payment already completed by the time the timeout triggered?
      if (!paymentComplete) {
        // Setting this to zero so it doesn't fade in the payment
        // processing backdrop again when the message changes on the next line.
        setProcessing({ isProcessing: true, content: paymentProcessingConfig.altDelayedMessage, fadeInDur: 0 })
      }
    }, paymentProcessingConfig.timeBeforeAltMessage);
  }

  // Make payment attempt
  const result = await MyApp.completePayment({
    amount: paymentAmount,
    nonce: payload.nonce,
    registerToken,
    deviceData
  });

  paymentComplete = true;

  if (result == null) {
    MyApp.setError("There was an error during the processing of the payment")
    return
  };

  // Error codes
  const paymentInternalRefErrorCode = "IR001"
  const paymentTimeoutErrorCode = "PP001"
  const paymentProcessingErrorCode = "PP002"

  // check for specific error codes and re-wash reason if required
  if (result.reasonCode) {
    if (result.reasonCode === paymentInternalRefErrorCode ||
      result.reasonCode === paymentProcessingErrorCode ||
      result.reasonCode === paymentTimeoutErrorCode) {
      result.reason = "There was an error processing your payment, please try again later."
    }
  }

  // Error Messages
  const havePaidError = "You have already paid your balance";

  if (result.reason) {
    if (result.reason.includes(havePaidError)) {
      // If the fail reason is that of an "already paid" result,
      // show this on the error page rather then in the FailedReceipt
      MyApp.setError(result.reason);
    } else {
      setFailedReceipt({
        reference: result.externalReference,
        account: result.consumerAccount ?? "",
        maxAttemptsReached: result.maxAttemptsReached ?? false,
        reason: result.reason,
        errorCode: result.reasonCode,
        maxAttemptsReason: result.maxAttemptsReason,
      });
    }
  } else {
    setSuccessReceipt({
      reference: result.externalReference,
      account: result.consumerAccount,
      amount: result.chargedAmount,
      cardRegistered: result.cardSaved
    });
  }
}


