import { Stack, Typography } from "@mui/material";

export type SecurePaymentProps = {
  domain: string;
  name: string;
};

export default function SecurePayment({ domain, name }: SecurePaymentProps) {
  return (
    <div className="secure-payment">
      <Stack direction="row">
        <img
          src="/static/images/lock.svg"
          alt={`Secure Payment Icon for ${name}`}
          style={{ height: "30px", marginRight: "4px" }}
        />
        <Stack>
          <Typography
            sx={{ lineHeight: "16px", fontWeight: "bold" }}
            variant="caption"
          >
            Secure payment
          </Typography>
          <Typography sx={{ lineHeight: "16px" }} variant="caption">
            Your payment will be processed securely.
          </Typography>
        </Stack>
      </Stack>
    </div>
  )
}