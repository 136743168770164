import React from "react";
import ReactDOM from "react-dom/client";
import { StyledEngineProvider } from '@mui/material/styles';
import reportWebVitals from "./reportWebVitals";
import { createTheme, CssBaseline, LinearProgress, ThemeProvider } from "@mui/material";
import CreateInstance, { MyApp } from "./context/AppContext";
import CSSLoader from "./components/default/CSSLoader";
import App from "./App";

CreateInstance()
const ContextProvider = MyApp.provider;
const Context = MyApp.context;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// With "React.StrictMode", components are rendering twice
// This only applies to development mode
root.render(
  <React.StrictMode>
    <React.Suspense fallback={<LinearProgress />}>
      <ContextProvider>
        <Context.Consumer>
          {(state) => {
            // Update reference
            MyApp.state = state;
            // Theme
            const theme = createTheme(state.theme);
            // Layout
            return (
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <StyledEngineProvider injectFirst>
                  <CSSLoader href={`${MyApp.domainPath}/css/styles.css`}>
                    <App />
                  </CSSLoader>
                </StyledEngineProvider>
              </ThemeProvider>
            );
          }}
        </Context.Consumer>
      </ContextProvider>
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
