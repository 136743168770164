
export default function NotFoundMessage() {
  return (
    <>
      This could be because:
      <ul style={{
        marginTop: "5px",
        marginBottom: "20px",
        paddingLeft: "20px"

      }}>
        <li>There's a typo</li>
        <li>It has been moved</li>
        <li>We've deleted it</li>
      </ul>
      Please contact customer support if you have any questions.
    </>
  )
}
