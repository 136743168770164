import { Backdrop, Stack, CircularProgress } from '@mui/material'
import React, { ReactNode } from 'react'

export const defaultProcessingState = {
  isProcessing: false,
  content: "",
  fadeInDur: 0
}

export type ProcessingBackDropProps = {
  isProcessing: boolean,
  content: string | ReactNode,
  fadeInDur: number,
  style?: React.CSSProperties,
}

const isString = (content: string | ReactNode): content is string => {
  return typeof content === 'string';
};

export default function ProcessingBackDrop(props: ProcessingBackDropProps) {
  if (props.isProcessing) {
    const backdropStyle = { ...props.style, color: "#fff", zIndex: 999 };
    return (
      <Backdrop sx={backdropStyle} transitionDuration={props.fadeInDur} open={true}>
        <Stack direction={"column"} alignItems={"center"} gap={1}>
          {isString(props.content) && <CircularProgress color="inherit" />}
          <div style={{ whiteSpace: 'pre-line', textAlign: 'center', textShadow: '0 0 20px black' }}>
            {props.content}
          </div>
        </Stack>
      </Backdrop>
    )
  } else {
    return null
  }
}