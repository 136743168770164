import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material'
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';

// Component props
type PaymentMethodAccordionPanelProps = {
  accordionExpanded: string | false,
  handleAccordionChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void,
  panelName: string,
  panelText: string,
  panelSubText?: string,
  panelIcon: string,
  domain: string,
  children?: ReactNode;
}

export default function PaymentMethodAccordionPanel(props: PaymentMethodAccordionPanelProps) {
  // Chevron icon used in the payment method accordion
  const Chevron = (
    <FontAwesomeIcon icon={faChevronDown} size="lg" className='accordion-chevron' />
  );

  return (
    <Accordion
      disableGutters
      square
      elevation={1}
      className="accordion"
      expanded={props.accordionExpanded === props.panelName}
      onChange={props.handleAccordionChange(props.panelName)}
    >
      <AccordionSummary id={`${props.panelName}-summary`}
        expandIcon={Chevron}
        className="accordion-summary"
      >
        <Stack spacing={2} direction="row" alignItems="center">
          <div className="summary-image-div">
            <img
              className={`summary-image-${props.panelName}`}
              src={`/static/images/${props.panelIcon}`}
              alt={`${props.panelText} Icon`}
            />
          </div>

          {props.panelSubText ? (
            <Stack>
              <Typography className="accordion-summary-text">{props.panelText}</Typography>
              <Typography variant="caption">
                {props.panelSubText}
              </Typography>
            </Stack>
          ) : (
            <Typography className="accordion-summary-text">
              {props.panelText}
            </Typography>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails className="accordion-details">
        {props.children}
      </AccordionDetails>
    </Accordion>
  )
}