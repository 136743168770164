import { Typography } from '@mui/material'

export default function TermsAndConditionsMessage() {
  return (
    <Typography
      variant="caption"
      sx={{
        margin: "15px",
        maxWidth: "325px",
        textAlign: "center",
      }}
    >
      Your use of this payment method is governed by the
      relevant terms and conditions of your payment provider.
    </Typography>
  )
}
