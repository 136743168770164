import React, { MouseEventHandler } from "react";

export type FailedReceiptLabels = {
  failedTitle?: string;
  failedMessage?: string;
  accountNumber?: string;
  paymentReference?: string;
  failedReason?: string;
  retry?: string;
};

export type FailedReceiptDto = {
  reference?: string;
  account: string;
  maxAttemptsReached: boolean;
  reason: string;
  errorCode: string;
  maxAttemptsReason?: string;
};

export type FailedReceiptProperties = {
  receipt: FailedReceiptDto;
  labels: FailedReceiptLabels;
  onRetry: () => void;
};

export default function FailedReceipt(props: FailedReceiptProperties) {
  const { receipt, labels, onRetry } = props;
  const linkDeactivatedError = 'URL is deactivated';
  // Disabling below setting for now, might be enabled or removed in
  // the next next wave of MPM changes pending confirmation.
  const displayErrorCodes = false;

  const retryClickHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    onRetry();
  };

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="Receipt-container">
      <div className="flex-col heading-container">
        <span className="title">{labels?.failedTitle}</span>
        <span className="message">{labels?.failedMessage}</span>
      </div>
      <hr />
      <div className="receipt-details-container">
        <div className="flex-row account-number-container">
          <label className="title">{labels?.accountNumber}</label>
          <label className="value">{receipt.account}</label>
        </div>
        {receipt.reference &&
          <div className="flex-row">
            <label className="title">{labels?.paymentReference}</label>
            <label className="value">{receipt.reference ?? "n/a"}</label>
          </div>
        }
        <div className="flex-row reason-container">
          <label className="title">{labels?.failedReason}</label>
          <label className="value">{receipt.reason}
          </label>
          {(receipt.errorCode && displayErrorCodes) &&
            <label className="error-code-message">
              Error Code: {receipt.errorCode}
            </label>
          }
        </div>
      </div>
      {receipt.maxAttemptsReached ? (
        <React.Fragment>
          <hr />
          <p className="text-danger link-status-message">
            {receipt.maxAttemptsReason}
          </p>
        </React.Fragment>
      ) : !receipt.reason.includes(linkDeactivatedError) ? (
        <div className="retry-container">
          <button
            type="submit"
            onClick={retryClickHandler}
          >
            {labels?.retry}
          </button>
        </div>
      ) : (
        undefined
      )}
    </div>
  );
}
