import { Divider } from '@mui/material';

export type ErrorDetailsProps = {
  errorLabel: string,
  error: string | undefined
}

export default function ErrorDetails({ errorLabel, error }: ErrorDetailsProps) {
  return (
    <div className="ErrorDetails-container">
      <h2>{errorLabel}</h2>
      <Divider className="error-divider"/>
      <div className="error-msg">{error}</div>
    </div>
  );
}