import { Typography } from '@mui/material'

export type PaySecurelyWithProps = {
  domain: string;
};

export default function PaySecurelyWith({ domain }: PaySecurelyWithProps) {
  return (
    <div className="pay-with-div">
      <Typography variant="body1" className="pay-with-title">
        Pay securely with
      </Typography>
      <div className="pay-with-list">
        <div className="pay-with-box-light">
          <img className="pay-with-visa-img" src="/static/images/visa-logo.svg" alt="Visa" title="Visa" />
        </div>

        <div className="pay-with-box-light">
          <img className="pay-with-mc-img" src="/static/images/mastercard-logo.svg" alt="Mastercard logo" title="Mastercard" />
        </div>

        <img className="pay-with-amex-img" src="/static/images/amex-logo-variant.svg" alt="American Express logo" title="American Express" />

        <div className="pay-with-box-light">
          <img className="pay-with-pp-img" src="/static/images/paypal-logo-stacked.png" alt="PayPal logo" title="PayPal" />
        </div>

        <img className="pay-with-apple-img" src="/static/images/apple-pay-logo.svg" alt="Apple Pay logo" title="Apple Pay" />
        <img className="pay-with-google-img" src="/static/images/google-pay-logo.png" alt="Google Pay logo" title="Google Pay" />
      </div>
    </div>
  )
}
