import React from 'react'

export type PayIn4MessageProps = {
  domain: string,
  amount: number,
  numberOfPayments: number
}

export default function PayIn4Message(props: PayIn4MessageProps) {
  let instalmentAmount = 0;

  if (props.amount && props.amount !== 0) {
    instalmentAmount = props.amount / props.numberOfPayments;
  }

  return (
    <React.Fragment>
      <div className="pay-in4-tail" />
      <div className="pay-in4-msg-div">
        <img className="pay-in4-img" src="/static/images/paypal-logo-horizontal.svg" alt="PayPal" />
        <div className="pay-in4-msg">
          Pay in 4 interest-free payments of ${instalmentAmount.toFixed(2)}.{' '}
          <a
            href="https://www.paypal.com/au/webapps/mpp/paypal-payin4"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
        </div>
      </div>
    </React.Fragment>
  )
}
