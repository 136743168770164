import React from "react";
import { Button, Stack, TextField } from "@mui/material";
import { AppState } from "../../../context/AppState";
import { PaymentDto } from "../../../api/dto/PaymentDto";

export type VariablePaymentProps = {
    currencySymbol: string;
    amount: number;
    labels: AppState['labels'];
    variablePaymentSettings?: PaymentDto['site']['variablePaymentSettings'];
    onReady: (amount: number) => void;
}

export function VariablePayment(props: VariablePaymentProps) {
    // Destruct
    const { currencySymbol, amount, labels, variablePaymentSettings, onReady } = props;

    const min = variablePaymentSettings?.minimumPartialPaymentAllowed || 0.01;
    const max = variablePaymentSettings?.maximumOverpaymentAllowed || amount;

    const amountRef = React.useRef<HTMLInputElement>();
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [error, setError] = React.useState<string>();

    const formatError = (error: string, amount: number) => {
        return error.replace('{0}', currencySymbol).replace("{1}", amount.toFixed(2));
    }

    const doSubmit = () => {
        // Check amount
        const input = amountRef.current;
        if (input == null) return;

        const amount = input.valueAsNumber;
        if (isNaN(amount) || amount <= 0) {
            setError(labels.invalidAmount);
            input.focus();
            return;
        }

        if (amount < min) {
            setError(formatError(labels.partialPaymentLessError, min));
            input.focus();
            return;
        }

        if (amount > max) {
            setError(formatError(labels.partialPaymentGreaterError, max));
            input.focus();
            return;
        }

        if (amount * 100 % 1 !== 0) {
            setError(labels.partialPaymentDecimalError);
            input.focus();
            return;
        }

        setError(undefined);

        onReady(amount);
    }

    // Layout
    return <Stack direction="column" alignItems="center" gap={2} className="variable-container">
        <TextField
            fullWidth
            label={labels.enterPaymentAmount}
            placeholder={labels.paymentAmountPlaceholder}
            type="number"
            inputProps={{
                min,
                max,
                step: 1,
                inputMode: "decimal",
            }}
            defaultValue={amount}
            inputRef={amountRef}
            onKeyUp={(event) => {
                if (event.key === 'Enter') {
                    buttonRef.current?.click();
                }
            }}
            error={error != null}
            helperText={error}
            InputProps={{ startAdornment: currencySymbol }}
        />
        <Button
            id="variable-button"
            variant="contained"
            ref={buttonRef}
            onClick={() => doSubmit()}
        >
            {labels.proceedButton}
        </Button>
    </Stack>
}