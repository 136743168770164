import { ReactNode } from 'react';
import { Divider } from '@mui/material';

export type MessageProps = {
  title: ReactNode,
  msg: ReactNode
}

export default function Message({ title, msg }: MessageProps) {
  return (
    <div className="Message-container" >
      <h2 className="message-label">{title}</h2>
      <Divider className='message-divider' />
      <div className="message-msg">{msg}</div>
    </div>
  );
}