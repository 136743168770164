type OptOutProps = {
  optOutHandler: () => Promise<void>;
  link: string;
};

export default function OptOut({ optOutHandler, link }: OptOutProps) {
  return (
    <div className="OptOut-container">
      <div className="optout-header">Payment Method Registered</div>
      <div className="optout-msg">To make things as simple as possible, we can
        register this payment method so in future you can pay your
        bills by simply replying <span>YES</span> to our <span>PayBySMS</span>.<br />
        If you don't want to do this, you can press the
        button below to opt out.<br />
        To find out more, see our{' '}
        <a target="_blank" rel="noreferrer" href={link}>
          Terms and Conditions
        </a>
      </div>
      <button className="optout-button" onClick={optOutHandler}>
        Opt-Out
      </button>
    </div>
  );
}