import { Alert, AlertTitle } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function ApplePayMessage() {
  return (
    <Alert
      square
      severity="warning"
      className="apple-pay-alert"
      iconMapping={{
        warning: <InfoOutlinedIcon fontSize="inherit" />,
      }}
    >
      <AlertTitle className="apple-pay-alert-header">Apple Pay not available on this device</AlertTitle>
      To learn which iPhone, iPad, Apple Watch, and Mac models are compatible with Apple Pay and where you can use them for your purchases and payments, click
      {' '}<a href="https://support.apple.com/en-us/HT208531">here.</a>
    </Alert>
  )
}
