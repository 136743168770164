import { Divider } from '@mui/material';

export default function OptOutSuccess() {
  return (
    <div className="OptOutSuccess-container" >
      <h2 className="optoutsuccess-label">Opt Out Successful</h2>
      <Divider className='optoutsuccess-divider' />
      <div className="optoutsuccess-msg">We have not stored your payment method details.</div>
    </div>
  );
}