import { CircularProgress, Stack } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { SharedUtils } from "../../../../utils/SharedUtils";

// +--------------------------------------------------+
//           CyberSource3DSChallenge Props
// +--------------------------------------------------+
interface CyberSource3DSProps {
  formActionUrl: string;
  mdValue: string;
  jwtAccessToken: string;
  challengeFrameWidth?: string;
  challengeFrameHeight?: string;
}

// +--------------------------------------------------+
//          CyberSource3DSChallenge Component
// +--------------------------------------------------+

export default function CyberSource3DSChallenge({
  formActionUrl,
  mdValue,
  jwtAccessToken,
  challengeFrameWidth = '100%',
  challengeFrameHeight = '400px'
}: CyberSource3DSProps) {
  const [isChallengeFrameVisible, setIsChallengeFrameVisible] = useState(true);
  const formRef = useRef<HTMLFormElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const iframeLoaded = useRef<boolean>(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsChallengeFrameVisible(false);

    // Submit the form programmatically after state update
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const handleIframeLoad = () => {
    if (iframeLoaded.current) {
      // Another page load after the first
      setIsChallengeFrameVisible(false);
    }

    iframeLoaded.current = true;

    if (iframeRef.current) {
      try {
        var sameOriginHref = iframeRef.current.contentWindow?.location.href;
        SharedUtils.debugLog('3DS Payment result received:');

        const iframeDocument = iframeRef.current.contentDocument;
        if (iframeDocument) {
          const result = iframeDocument.body.children[0].innerHTML;
          var paymentResult = JSON.stringify({ "MessageType": "payment.result", "Result": result });
          SharedUtils.debugLog('3DS Payment result event raised');
          window.postMessage(paymentResult);
        }
      } catch (err) {

      }
    }
  };

  useEffect(() => {
    if (formActionUrl && mdValue && jwtAccessToken) {
      if (formRef.current) {
        formRef.current.submit();
      }
    }
  }, [formActionUrl, mdValue, jwtAccessToken]);

  return (
    <>
      {!isChallengeFrameVisible && (
        <Stack direction={"column"} alignItems={"center"} gap={1}>
          <CircularProgress color="inherit" />
          <div style={{ whiteSpace: 'pre-line', textAlign: 'center', textShadow: '0 0 20px black' }}>
            {'Please continue to wait...\nProcessing 3DS response'}
          </div>
        </Stack>
      )}
      <div style={{
        background: "#eee",
        width: challengeFrameWidth,
        height: challengeFrameHeight,
        display: isChallengeFrameVisible ? 'block' : 'none'
      }}>
        <iframe
          id="cybersource-3ds-iframe"
          name="cybersource-3ds-iframe"
          width={challengeFrameWidth}
          height={challengeFrameHeight}
          title='cybersource-3ds-iframe'
          onLoad={handleIframeLoad}
          ref={iframeRef}
        ></iframe>
        <form
          id="cybersource-3ds-form"
          method="POST"
          target="cybersource-3ds-iframe"
          action={formActionUrl}
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <input id="cybersource-3ds-jwt" type="hidden" name="JWT" value={jwtAccessToken} />
          <input id="cybersource-3ds-md" type="hidden" name="MD" value={mdValue} />
        </form>
      </div>
    </>
  );
}
