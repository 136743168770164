import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import PayIn4Message from './PayIn4Message'

export type PayIn4FormProps = {
  domain: string,
  amount: number,
  selectedPaypalOption: string,
  handlePaypalOptionChange: (event: any) => void
}

export default function PayIn4Form(props: PayIn4FormProps) {
  return (
    <div className="PayIn4Form-div" style={{ textAlign: "left", width: "90%", marginTop: "15px" }}>
      <Typography variant="body1">Select an option:</Typography>
      <RadioGroup
        aria-label="paypal-payment-options"
        name="paypalPaymentOptions"
        value={props.selectedPaypalOption}
        onChange={props.handlePaypalOptionChange}
      >
        <FormControlLabel
          value="paynow"
          control={<Radio size="medium" sx={{ '&.Mui-checked': { color: '#26336a' } }} />}
          label="Pay now"
          defaultChecked
          sx={{ marginBottom: "-5px" }}
        />
        <FormControlLabel
          value="payin4"
          control={<Radio size="medium" sx={{ '&.Mui-checked': { color: '#26336a' } }} />}
          label="Pay in 4"
        />
      </RadioGroup>

      {props.selectedPaypalOption === 'payin4' && (
        <PayIn4Message domain={props.domain} amount={props.amount} numberOfPayments={4} />
      )}
    </div>
  )
}
