import { DisplayCardType } from "../../../api/dto/PaymentConfigDto";

enum CardTypes {
  visa, mastercard, amex, none
}

export type CardTypeDisplayProps = {
  activeCardType: CardTypes
  displayCardTypes?: DisplayCardType[]
}

export default function CardTypeDisplay(props: CardTypeDisplayProps) {
  const { activeCardType, displayCardTypes } = props;

  const visaClass = activeCardType === CardTypes.visa ? '' : 'grey';
  const mastercardClass = activeCardType === CardTypes.mastercard ? '' : 'grey';
  const amexClass = activeCardType === CardTypes.amex ? '' : 'grey';

  const displayVisaCardType = (key: number, imageUrl: string) => (
    <div className="card-type" key={key}>
      <img id="card-visa" className={visaClass} src={imageUrl} alt="Visa" />
    </div>
  )

  const displayMasterCardType = (key: number, imageUrl: string) => (
    <div className="card-type" key={key}>
      <img id="card-mc" className={mastercardClass} src={imageUrl} alt="Mastercard" />
    </div>
  )

  const displayAmexCardType = (key: number, imageUrl: string) => (
    <div className="card-type" key={key}>
      <img id="card-amex" className={amexClass} src={imageUrl} alt="Amex" />
    </div>
  )

  return (
    <div className="card-types">
      {displayCardTypes?.map((card, index) => {
        if (card.cardType === "visa") return displayVisaCardType(index, card.cardTypeImage);
        if (card.cardType === "mastercard") return displayMasterCardType(index, card.cardTypeImage);
        if (card.cardType === "amex") return displayAmexCardType(index, card.cardTypeImage);
        return undefined;
      })}
    </div>
  )
}
