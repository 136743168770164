import React, { LegacyRef } from 'react'
import PaymentAmountMessage from '../PaymentAmountMessage'
import TermsAndConditionsMessage from '../TermsAndConditionsMessage'
import ApplePayMessage from '../ApplePayMessage'
import SecurePayment from '../SecurePayment'
import { MyApp } from "../../../../context/AppContext";
import { PaymentDto } from '../../../../api/dto/PaymentDto'
import { FailedReceiptDto } from "../../body/FailedReceipt";
import { SuccessReceiptDto } from "../../body/SuccessReceipt";
import { CompleteApplePaymentResponseDto } from "../../../../api/dto/CompleteApplePaymentResponseDto";

export type PaymentMethodApplePayProps = {
  isAvailable: boolean,
  amountTotal: number,
  currencySymbol: string,
  aboutToPayMessageEnabled: boolean,
  domain: string,
  applePayRef: LegacyRef<HTMLButtonElement> | undefined,
  paymentData: PaymentDto,
  setFailedReceipt: React.Dispatch<React.SetStateAction<FailedReceiptDto | undefined>> | undefined;
  setSuccessReceipt: React.Dispatch<React.SetStateAction<SuccessReceiptDto | undefined>> | undefined;
}

declare const ApplePaySession: any;

export default function PaymentMethodApplePay(props: PaymentMethodApplePayProps) {
  if (props.isAvailable) {
    return (
      <div className="payment-section-div">
        <div style={{ textAlign: "left", width: "90%" }}>
          {props.aboutToPayMessageEnabled && <PaymentAmountMessage currencySymbol={props.currencySymbol} amount={props.amountTotal} />}
        </div>
        <TermsAndConditionsMessage />

        <button id="apple-pay-button" className="pay-button gpay" ref={props.applePayRef} onClick={() => {
          const paymentRequest = {
            countryCode: props.paymentData.site.siteConfig['AppleCountryCode'],
            currencyCode: props.paymentData.site.currencyCode,
            supportedNetworks: props.paymentData.site.siteConfig['AllowedCardNetworks'].split(','),
            merchantCapabilities: props.paymentData.site.siteConfig['MerchantCapabilities'].split(','),
            total: {
              label: props.paymentData.site.siteConfig['AppleDisplayLabel'],
              amount: props.amountTotal
            }
          };

          const session = new ApplePaySession(3, paymentRequest);

          session.onvalidatemerchant = async function (event: any) {
            // Call your own server to request a new merchant session.
            try {
              let response = await MyApp.applePayGetPaymentSession(event.validationURL);
              if (!response) {
                // if response is undefined/null we had problems getting an internal ref
                session.abort();
                MyApp.setError("Unable to create Apple Pay session, please try again soon.");
                return;
              }

              if (response.reason) {
                // Reason returned from the api, link might be expired/deactivated/already paid etc
                session.abort();
                MyApp.setError(response.reason);
              } else {
                // valid response so process..
                let json = JSON.parse(response.merchantSessionJson ?? '');
                MyApp.setInternalRef(response.internalRef ?? '');

                session.completeMerchantValidation(json);
              }
            } catch (error: any) {
              // Some other error trying to create a merchant session.
              session.abort();
              MyApp.setError("Unable to create Apple Pay session, please try again soon.");
            }
          };

          session.onpaymentauthorized = async function (event: any) {
            var paymentDataString = JSON.stringify(event.payment.token.paymentData);
            var paymentDataBase64 = btoa(paymentDataString);
            // Send payment information to your server here to process the payment

            var isPaymentSuccess = false;
            var paymentResponse: CompleteApplePaymentResponseDto | undefined;

            try {
              paymentResponse = await MyApp.applePayPayment(paymentDataBase64);
              paymentResponse = paymentResponse as CompleteApplePaymentResponseDto;
              isPaymentSuccess = paymentResponse.success;
            }
            catch (error: any) {
              if (props.setFailedReceipt) {
                props.setFailedReceipt({
                  account: props.paymentData.accountNumber ?? "",
                  reason: 'An error occurred during payment processing. Please try again later..',
                  errorCode: 'Unknown',
                  maxAttemptsReached: false,
                });
              }

              session.completePayment(ApplePaySession.STATUS_FAILURE);
              return;
            }

            if (isPaymentSuccess) {
              MyApp.setCardPin("APPL");

              if (props.setSuccessReceipt) {
                props.setSuccessReceipt({
                  reference: paymentResponse.externalReference ?? "",
                  account: props.paymentData.accountNumber ?? "",
                  amount: props.paymentData.amount,
                  cardRegistered: paymentResponse.cardSaved
                });
              }

              session.completePayment(ApplePaySession.STATUS_SUCCESS);
            }
            else {
              if (props.setFailedReceipt) {
                props.setFailedReceipt({
                  reference: paymentResponse.externalReference,
                  account: props.paymentData.accountNumber ?? "",
                  maxAttemptsReached: paymentResponse.maxAttemptsReached as boolean,
                  reason: paymentResponse.reason ?? "",
                  errorCode: paymentResponse.reasonCode ?? "",
                  maxAttemptsReason: paymentResponse.maxAttemptsReason,
                });
              }

              session.completePayment(ApplePaySession.STATUS_FAILURE);
            }
          };

          session.begin();
        }}>
          <div
            className="paypal-button-label-container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="pay-button-label">Pay with</span>
            <img
              src="/static/images/apple-pay-logo.svg"
              alt="Apple Pay Button Icon"
              style={{ height: "40px", marginLeft: "-5px" }}
            />
          </div>
        </button>

        <SecurePayment domain={props.domain} name="Apple Pay" />
      </div>
    )
  } else {
    return (
      <div className="payment-section-div">
        <div>
          <ApplePayMessage />
        </div>
      </div>
    )
  }
}
