import PaymentAmountMessage from './PaymentAmountMessage'
import TermsAndConditionsMessage from './TermsAndConditionsMessage'
import SecurePayment from './SecurePayment'
import { LegacyRef } from 'react'

export type PaymentMethodGooglePayProps = {
  amountTotal: number,
  currencySymbol: string,
  aboutToPayMessageEnabled: boolean,
  domain: string,
  googlePayRef: LegacyRef<HTMLDivElement> | undefined
}

export default function PaymentMethodGooglePay(props: PaymentMethodGooglePayProps) {
  return (
    <div className="payment-section-div">
      <div>
        {props.aboutToPayMessageEnabled && <PaymentAmountMessage currencySymbol={props.currencySymbol} amount={props.amountTotal} />}
      </div>
      <TermsAndConditionsMessage />
      <div className="payment-button-googlepay" ref={props.googlePayRef} />
      <SecurePayment domain={props.domain} name="Google Pay" />
    </div>
  )
}
