import React, { LegacyRef } from 'react'
import PaymentAmountMessage from './PaymentAmountMessage'
import TermsAndConditionsMessage from './TermsAndConditionsMessage'
import ApplePayMessage from './ApplePayMessage'
import SecurePayment from './SecurePayment'
import { PaymentDto } from '../../../api/dto/PaymentDto'
import { FailedReceiptDto } from "../body/FailedReceipt";
import { SuccessReceiptDto } from "../body/SuccessReceipt";

export type PaymentMethodApplePayProps = {
  isAvailable: boolean,
  amountTotal: number,
  currencySymbol: string,
  aboutToPayMessageEnabled: boolean,
  domain: string,
  applePayRef: LegacyRef<HTMLButtonElement> | undefined,
  paymentData: PaymentDto,
  setFailedReceipt: React.Dispatch<React.SetStateAction<FailedReceiptDto | undefined>> | undefined;
  setSuccessReceipt: React.Dispatch<React.SetStateAction<SuccessReceiptDto | undefined>> | undefined;
}

export default function PaymentMethodApplePay(props: PaymentMethodApplePayProps) {
  if (props.isAvailable) {
    return (
      <div className="payment-section-div">
        <div style={{ textAlign: "left", width: "90%" }}>
          {props.aboutToPayMessageEnabled && <PaymentAmountMessage currencySymbol={props.currencySymbol} amount={props.amountTotal} />}
        </div>
        <TermsAndConditionsMessage />

        <button id="apple-pay-button" className="pay-button gpay" ref={props.applePayRef}>
          <div
            className="paypal-button-label-container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="pay-button-label">Pay with</span>
            <img
              src="/static/images/apple-pay-logo.svg"
              alt="Apple Pay Button Icon"
              style={{ height: "40px", marginLeft: "-5px" }}
            />
          </div>
        </button>

        <SecurePayment domain={props.domain} name="Apple Pay" />
      </div>
    )
  } else {
    return (
      <div className="payment-section-div">
        <div>
          <ApplePayMessage />
        </div>
      </div>
    )
  }
}
