import { Typography } from '@mui/material'

export type PaymentAmountMessageProps = {
  amount: number,
  currencySymbol: string
}

export default function PaymentAmountMessage(props: PaymentAmountMessageProps) {
  return (
    <Typography variant="body1" className="payment-amount">
      You're about to pay {props.currencySymbol}{props.amount.toFixed(2)}
    </Typography>
  )
}
