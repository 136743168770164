import { PaymentDto } from "../api/dto/PaymentDto";

export namespace SharedUtils {
  /**
   * Get error message
   * @param error Error object
   * @returns Message
   */
  export function getErrorMessage(error: object | null) {
    const message =
      error == null
        ? "Unknown Error"
        : "message" in error
          ? `${error.message}`
          : JSON.stringify(error);
    return message;
  }

  export function parseConfig<T>(name: string, payment: PaymentDto): T | undefined {
    if (payment == null) return undefined;
    let value = payment.site.siteConfig[name];
    if (value == null || value === "") return undefined;
    return JSON.parse(value) as T;
  }

  export function debugLog(...args: any[]): void {
    if (shouldEnableLogs()) {
      console.log(...args);
    }
  }

  function shouldEnableLogs(): boolean {
    return process.env.REACT_APP_ENABLE_LOGGING === 'true';
  }
}
