import React from "react";
// MUI imports
import { Skeleton, Typography } from "@mui/material";
import { Container } from "@mui/system";
// Payment provider related
import { IllionBraintree } from "@illiondts/braintree";
import { CardOptions } from "@illiondts/braintree/lib/mjs/methods/CardOptions";
import { PaypalOptions } from "@illiondts/braintree/lib/mjs/methods/PaypalOptions";
import { ApplePayOptions } from "@illiondts/braintree/lib/mjs/methods/ApplePayOptions";
import { GooglePayOptions } from "@illiondts/braintree/lib/mjs/methods/GooglePayOptions";
// Payment methods
import PaymentMethodGooglePay from "../PaymentMethodGooglePay";
import PaymentMethodApplePay from "../PaymentMethodApplePay";
import PaymentMethodPayPal, { payIn4Available } from "../PaymentMethodPayPal";
import PaymentMethodCreditCard, { CardTypes, FieldStatus, defaultFieldStatuses } from "../PaymentMethodCreditCard";
// General
import PaymentMethodAccordionPanel from "../PaymentMethodAccordionPanel";
import PaySecurelyWith from "../PaySecurelyWith";
import { PaymentDto } from "../../../../api/dto/PaymentDto";
import { MyApp } from "../../../../context/AppContext";
import {
  onBlurHandler,
  onFocusHandler,
  onCardTypeChangeHandler,
  onValidityChangeHandler,
  onPaymentRequestableHandler,
  onSubmitHandler
} from "./PaymentBrainTreeHandlers";
import { FailedReceiptDto } from "../../body/FailedReceipt";
import { SuccessReceiptDto } from "../../body/SuccessReceipt";
import { PaymentPayload } from "@illiondts/braintree/lib/mjs/data/PaymentPayload";
import ProcessingBackDrop, { ProcessingBackDropProps, defaultProcessingState } from "../ProcessingBackDrop";
import { SharedUtils } from "../../../../utils/SharedUtils";
import { EnabledPaymentMethods } from "../../../../api/dto/PaymentConfigDto";

// +--------------------------------------------------+
//               PaymentMethods Props
// +--------------------------------------------------+

export type PaymentBrainTreeProps = {
  domain: string;
  paymentAmount: number;
  currencySymbol: string,
  enabledPaymentMethods: EnabledPaymentMethods;
  aboutToPayMessageEnabled: boolean;
  paymentData: PaymentDto;
  setFailedReceipt: React.Dispatch<React.SetStateAction<FailedReceiptDto | undefined>>
  setSuccessReceipt: React.Dispatch<React.SetStateAction<SuccessReceiptDto | undefined>>
};

// +--------------------------------------------------+
//              PaymentMethods Component
// +--------------------------------------------------+

export function PaymentBrainTree(props: PaymentBrainTreeProps) {
  //const { domain, paymentData, card, paypal, applePay, googlePay, ...rest } = props;
  const { domain, currencySymbol, paymentAmount, paymentData, enabledPaymentMethods, aboutToPayMessageEnabled, setFailedReceipt, setSuccessReceipt } = props;

  // Tracks state of which accordion item is expanded and closes the rest
  const [accordionExpanded, setAccordionExpanded] = React.useState<string | false>(false);
  // Accordion change handler
  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setAccordionExpanded(isExpanded ? panel : false);
  };

  // Used by the Credit Card section
  const [cardType, setCardType] = React.useState<CardTypes>(CardTypes.none);
  const [fieldStatus, setFieldStatus] = React.useState<FieldStatus>(defaultFieldStatuses);

  // Processing backdrop with spinner and message that the payment is being processed
  const [processing, setProcessing] = React.useState<ProcessingBackDropProps>(defaultProcessingState);

  // If someone tries to close the browser tab they are on
  // during the processing of the payment, display a popup warning.
  React.useEffect(() => {
    if (!processing.isProcessing) return;

    function warnClosing(event: BeforeUnloadEvent) {
      let message = "Your payment is being processed, please do not close the window.";
      event.returnValue = message;
      return message;
    }

    window.addEventListener("beforeunload", warnClosing);

    return () => {
      window.removeEventListener("beforeunload", warnClosing);
    };
  }, [processing.isProcessing])

  // Might be used again in the future, can remove if needed
  // const creditCardDefaults: CardOptions = { "styles": { "input": "braintree-hosted-fields" } };

  // Default settings
  const googlePayDefaults: GooglePayOptions = { totalPriceStatus: "FINAL", version: 2, buttonOptions: { buttonSizeMode: 'fill', buttonType: "pay" } };
  const payPalDefaults: PaypalOptions = { "enable-funding": "paylater", fundingSource: "paypal,paylater", buttonStyle: { color: "gold", label: "pay", height: 40 } };

  // NOTE: PayPal settings
  // the "buyer-country" setting is only used in sandbox. Please use the site configuration to set it for testing
  // It should NOT be specified in production as it will cause an issue with loading the PayPal JS SDK.
  // "buyer-country": "AU"

  const { paymentProcessingConfig, paymentConfig, labels } = MyApp.state;

  // Parse/Deserialize payment method options from the payment link data
  var cardOptions = SharedUtils.parseConfig<CardOptions>("card", paymentData);
  var applePayOptions = SharedUtils.parseConfig<ApplePayOptions>("applePay", paymentData);
  var googlePayOptions = SharedUtils.parseConfig<GooglePayOptions>("googlePay", paymentData);
  var paypalOptions = SharedUtils.parseConfig<PaypalOptions>("paypal", paymentData);

  const deviceDataRef = React.useRef<string>();

  return (
    <IllionBraintree
      authorization={paymentData.clientToken}
      amount={{ currency: paymentData.site.currencyCode, total: paymentAmount }}
      environment={paymentData.site.siteConfig["environment"] as any}
      onDataCollected={(data: any) => { deviceDataRef.current = data.deviceData; }}
      onLoading={() => <Skeleton variant="rectangular" height={420} />}
      onError={(method, reason) => SharedUtils.debugLog("onError", method, reason)}
      onPaymentError={(method, reason) => SharedUtils.debugLog("onPaymentError", method, reason)}
      googlePay={{ ...googlePayDefaults, ...googlePayOptions }}
      paypal={{ ...payPalDefaults, ...paypalOptions }}
      applePay={{ ...applePayOptions }}
      card={{
        ...cardOptions,
        onSubmit(state) { onSubmitHandler(state, setFieldStatus) },
        setup: (instance) => {
          instance.on("focus", (event) => { onFocusHandler(event); });
          instance.on("blur", (event) => { onBlurHandler(event); });
          instance.on("validityChange", (event) => { onValidityChangeHandler(event, setFieldStatus); });
          instance.on("cardTypeChange", (event) => { onCardTypeChangeHandler(event, setCardType, setFieldStatus); });
        }
      }}
      onPaymentStart={() => {
        setProcessing({ isProcessing: true, content: paymentProcessingConfig.processingMessage, fadeInDur: paymentProcessingConfig.messageFadeInDuration })
      }}
      onPaymentEnd={() => {
        setProcessing({ isProcessing: false, content: "", fadeInDur: paymentProcessingConfig.messageFadeInDuration })
      }}
      onPaymentRequestable={async (method: string, payload: PaymentPayload) => {
        await onPaymentRequestableHandler(method, payload, paymentAmount, paymentProcessingConfig, deviceDataRef,
          setProcessing, setFailedReceipt, setSuccessReceipt)
      }}
    >
      {(methods, amount) => {
        return (
          <div className="Payment-container">
            {ProcessingBackDrop(processing)}

            <Typography mb={2} variant="h2" className="header">
              How would you like to pay?
            </Typography>
            {/* 
            -----------------------------
                     Credit Card
            -----------------------------
            */}
            {enabledPaymentMethods?.CreditCardEnabled && methods.card && (
              <PaymentMethodAccordionPanel
                accordionExpanded={accordionExpanded}
                handleAccordionChange={handleAccordionChange}
                panelName="cardPanel"
                panelText="Credit Card"
                panelIcon="card-pay.png"
                domain={domain}
              >
                <PaymentMethodCreditCard aboutToPayMessageEnabled={aboutToPayMessageEnabled} currencySymbol={currencySymbol} fieldStatus={fieldStatus} cardType={cardType} amountTotal={amount.total} domain={domain} creditCardRef={methods.card} displaySurcharge={true} displayCardTypes={paymentConfig?.displayCardTypes} labels={labels} />
              </PaymentMethodAccordionPanel>
            )}
            {/* 
            -----------------------------
                       PayPal 
            -----------------------------
            */}
            {enabledPaymentMethods.PayPalEnabled && methods.paypal && (
              <PaymentMethodAccordionPanel
                accordionExpanded={accordionExpanded}
                handleAccordionChange={handleAccordionChange}
                panelName="paypalPanel"
                panelText="PayPal"
                panelSubText={payIn4Available(amount.total) ? 'Pay now or Pay in 4' : 'Pay now'}
                panelIcon="paypal-logo-stacked.png"
                domain={domain}
              >
                <PaymentMethodPayPal aboutToPayMessageEnabled={aboutToPayMessageEnabled} currencySymbol={currencySymbol} amountTotal={amount.total} domain={domain} paypalRef={methods.paypal} />
              </PaymentMethodAccordionPanel>
            )}
            {/* 
            -----------------------------
                     Apple Pay 
            -----------------------------
            */}
            {enabledPaymentMethods.ApplePayEnabled && (
              <PaymentMethodAccordionPanel
                accordionExpanded={accordionExpanded}
                handleAccordionChange={handleAccordionChange}
                panelName="applepayPanel"
                panelText="Apple Pay"
                panelIcon="apple-pay-logo.svg"
                domain={domain}
              >
                <PaymentMethodApplePay aboutToPayMessageEnabled={aboutToPayMessageEnabled} currencySymbol={currencySymbol} isAvailable={!!methods.applePay} amountTotal={amount.total} domain={domain} applePayRef={methods.applePay} paymentData={paymentData} setFailedReceipt={undefined} setSuccessReceipt={undefined} />
              </PaymentMethodAccordionPanel>
            )}
            {/* 
            -----------------------------
                     Google Pay 
            -----------------------------
            */}
            {enabledPaymentMethods.GooglePayEnabled && methods.googlePay && (
              <PaymentMethodAccordionPanel
                accordionExpanded={accordionExpanded}
                handleAccordionChange={handleAccordionChange}
                panelName="gpayPanel"
                panelText="Google Pay"
                panelIcon="google-pay-logo.png"
                domain={domain}
              >
                <PaymentMethodGooglePay aboutToPayMessageEnabled={aboutToPayMessageEnabled} currencySymbol={currencySymbol} amountTotal={amount.total} domain={domain} googlePayRef={methods.googlePay} />
              </PaymentMethodAccordionPanel>
            )}

            {/* 
            -----------------------------
               Supported payment cards 
            -----------------------------
            */}
            <PaySecurelyWith domain={domain} />
          </div>
        );
      }}
    </IllionBraintree >
  );
}