import React, { useState } from 'react';
import { Collapse } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons'
//import './SurchargeDetails.css';

export default function SurchargeDetails() {
  const [isOpen, setIsOpen] = useState(false);
  const handleIconClick = () => {
    setIsOpen(!isOpen);
    setIconRotation(iconRotation === 0 ? 90 : 0);
  };
  const [iconRotation, setIconRotation] = useState(0);

  return (
    <div className="surcharge-div">
      <div className="surcharge-container">
        <span className="surcharge-span1">There will be a small processing fee for this payment and it will appear on your next bill.</span>
        <div>
          <span className="surcharge-span2">How are our fees calculated?</span>
          <FontAwesomeIcon
            tabIndex={0}
            icon={faCircleChevronRight}
            onClick={handleIconClick}
            onKeyUp={(event) => { if (event.key === 'Enter') handleIconClick() }}
            style={{ transform: `rotate(${iconRotation}deg)`, fontWeight: "900" }}
          />
        </div>
      </div>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <div id="surcharge_rates_table" className="surcharge-table-container">
          <table className="surcharge-table" >
            <tbody>
              <tr><th>Card</th><th>Fee (Exc GST)</th></tr>
              <TableRow cardType="VISA Credit Premium" fee="1.032%" />
              <TableRow cardType="VISA Credit Standard" fee="0.590%" />
              <TableRow cardType="VISA Debit Premium" fee="0.148%" />
              <TableRow cardType="VISA Debit Standard" fee="0.176%" />
              <TableRow cardType="VISA International" fee="2.072%" />
              <TableRow cardType="VISA Super Premium" fee="1.087%" />
              <TableRow cardType="Mastercard Credit Standard" fee="0.697%" />
              <TableRow cardType="Mastercard Debit Standard" fee="0.344%" />
              <TableRow cardType="Mastercard International" fee="2.071%" />
              <TableRow cardType="American Express" fee="0.940%" />
            </tbody>
          </table>
        </div>
      </Collapse>

    </div>
  );
}

export type TableRowProps = {
  cardType: string,
  fee: string
}

function TableRow(props: TableRowProps) {
  return <tr><td>{props.cardType}</td><td>{props.fee}</td></tr>;
}