import React, { LegacyRef } from 'react'
import PayIn4Form from './PayIn4Form'
import { Divider, Typography } from '@mui/material'
import PaymentAmountMessage from './PaymentAmountMessage'
import SecurePayment from './SecurePayment'
import TermsAndConditionsMessage from './TermsAndConditionsMessage'
import { SharedUtils } from '../../../utils/SharedUtils'

// +--------------------------------------------------+
//             Pay in 4 availability check
// +--------------------------------------------------+
export const payIn4Available = (amount: number) => {
  return amount >= 30 && amount <= 2000;
};

export type PaymentMethodPayPalProps = {
  amountTotal: number,
  currencySymbol: string,
  aboutToPayMessageEnabled: boolean,
  domain: string,
  paypalRef: LegacyRef<HTMLDivElement> | undefined
}

export default function PaymentMethodPayPal(props: PaymentMethodPayPalProps) {
  // PayPal options if amount conditions are met, otherwise default to "paynow"
  const [selectedPaypalOption, setSelectedPaypalOption] = React.useState("paynow");

  const handlePaypalOptionChange = (event: any) => {
    SharedUtils.debugLog("PaypalOptionChange:", event.target.value);
    setSelectedPaypalOption(event.target.value);
  };

  return (
    <div className="payment-section-div">
      {payIn4Available(props.amountTotal) && (
        <PayIn4Form
          domain={props.domain}
          amount={props.amountTotal}
          selectedPaypalOption={selectedPaypalOption}
          handlePaypalOptionChange={handlePaypalOptionChange}
        />
      )}

      <div>
        {props.aboutToPayMessageEnabled && <PaymentAmountMessage currencySymbol={props.currencySymbol} amount={props.amountTotal} />}
      </div>

      <TermsAndConditionsMessage />
      <Divider sx={{ width: "90%" }} />

      <Typography variant="body1" margin="15px">
        You will be temporarily redirected to PayPal.
      </Typography>

      <div ref={props.paypalRef} className="paypal-button">
        <div id="fundingsource-paypal" style={{ display: selectedPaypalOption !== "payin4" ? "block" : "none" }} />
        <div id="fundingsource-paylater" style={{ display: selectedPaypalOption === "payin4" ? "block" : "none" }} />
      </div>

      <SecurePayment domain={props.domain} name="Paypal" />
    </div>
  )
}
