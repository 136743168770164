
import React from "react";
import { PaymentDto } from "../../../../api/dto/PaymentDto";
import { FailedReceiptDto } from "../../body/FailedReceipt";
import { SuccessReceiptDto } from "../../body/SuccessReceipt";
import PaymentMethodAccordionPanel from "../PaymentMethodAccordionPanel";
import IllionCyberSource from "./IllionCyberSource";
import { Container, Skeleton, Typography } from "@mui/material";
import PaymentMethodApplePay from "../cybersource/PaymentMethodApplePay";
import PaymentMethodCreditCard, { CardTypes, FieldStatus, FieldStatusCyberSource, defaultFieldStatuses, defaultFieldStatusesCyberSource } from "../PaymentMethodCreditCard";
import { jwtDecode } from "jwt-decode";
import ProcessingBackDrop, { ProcessingBackDropProps, defaultProcessingState } from "../ProcessingBackDrop";
import { onCardTypeChangeHandler, onPaymentRequestableHandler, onValidityChangeHandler } from "./PaymentCyberSourceHandlers";
import { MyApp } from "../../../../context/AppContext";
import { EnabledPaymentMethods } from "../../../../api/dto/PaymentConfigDto";
import { AccordionConfigDto } from "../../../../api/dto/accordionConfigDto";

// +--------------------------------------------------+
//              PaymentCyberSource Props
// +--------------------------------------------------+

export type PaymentCyberSourceProps = {
  domain: string;
  paymentAmount: number;
  currencySymbol: string,
  paymentData: PaymentDto;
  enabledPaymentMethods: EnabledPaymentMethods;
  aboutToPayMessageEnabled: boolean,
  setFailedReceipt: React.Dispatch<React.SetStateAction<FailedReceiptDto | undefined>>
  setSuccessReceipt: React.Dispatch<React.SetStateAction<SuccessReceiptDto | undefined>>
};

// +--------------------------------------------------+
//             PaymentCyberSource Component
// +--------------------------------------------------+

export default function PaymentCyberSource(props: PaymentCyberSourceProps) {
  //const { domain, paymentAmount, paymentData, setFailedReceipt, setSuccessReceipt } = props;
  const {
    domain,
    paymentData,
    paymentAmount,
    currencySymbol,
    enabledPaymentMethods,
    aboutToPayMessageEnabled,
    setFailedReceipt,
    setSuccessReceipt
  } = props;

  const { paymentProcessingConfig, accordionConfig, paymentConfig, labels } = MyApp.state;

  // Tracks state of which accordion item is expanded and closes the rest
  const [accordionExpanded, setAccordionExpanded] = React.useState<string | false>(false);
  // Accordion Handler
  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setAccordionExpanded(isExpanded ? panel : false);
  };

  // Used by the Credit Card section
  const [cardType, setCardType] = React.useState<CardTypes>(CardTypes.none);
  const [fieldStatus, setFieldStatus] = React.useState<FieldStatusCyberSource>(defaultFieldStatusesCyberSource);

  // Processing backdrop with spinner and message that the payment is being processed
  const [processing, setProcessing] = React.useState<ProcessingBackDropProps>(defaultProcessingState);
  const deviceDataRef = React.useRef<string>();

  return (
    <IllionCyberSource
      amount={{ currency: paymentData.site.currencyCode, total: paymentAmount }}
      onCardTypeChange={(event) => { onCardTypeChangeHandler(event, setCardType, setFieldStatus) }}
      onValidityChangeHandler={(event) => { onValidityChangeHandler(event, setFieldStatus) }}
      onPaymentError={(method, reason) => {
        MyApp.setError(reason);
      }}
      onLoading={() => <Skeleton variant="rectangular" height={95} />}
      onPaymentStart={() => {
        setProcessing({ isProcessing: true, content: paymentProcessingConfig.processingMessage, fadeInDur: paymentProcessingConfig.messageFadeInDuration })
      }}
      onPaymentEnd={() => {
        setProcessing({ isProcessing: false, content: "", fadeInDur: paymentProcessingConfig.messageFadeInDuration })
      }}
      onPaymentRequestable={async (method: string, transientToken: string, cardHolderName: string) => {
        await onPaymentRequestableHandler(method, transientToken, paymentAmount, paymentProcessingConfig, deviceDataRef,
          setProcessing, setFailedReceipt, setSuccessReceipt, cardHolderName)
      }}
    >
      {(methods, amount) => {
        return (
          <div className="Payment-container">
            {ProcessingBackDrop(processing)}

            <Typography mb={2} variant="h2" className="header">
              How would you like to pay?
            </Typography>
            {/* 
          -----------------------------
                  Credit Card 
          -----------------------------
          */}
            {enabledPaymentMethods.CreditCardEnabled &&
              <PaymentMethodAccordionPanel
                accordionExpanded={accordionExpanded}
                handleAccordionChange={handleAccordionChange}
                panelName="cardPanel"
                panelText={accordionConfig!.Card?.title ?? 'Credit Card'}
                panelIcon="card-pay.png"
                domain={domain}
              >
                <PaymentMethodCreditCard
                  aboutToPayMessageEnabled={aboutToPayMessageEnabled}
                  currencySymbol={currencySymbol}
                  fieldStatus={fieldStatus} cardType={cardType}
                  amountTotal={amount.total}
                  domain={domain}
                  creditCardRef={methods.card}
                  displaySurcharge={false}
                  displayCardTypes={paymentConfig?.displayCardTypes}
                  labels={labels}
                />
              </PaymentMethodAccordionPanel>
            }
            {/* 
          -----------------------------
                    Apple Pay 
          -----------------------------
          */}
            {enabledPaymentMethods.ApplePayEnabled &&
              <PaymentMethodAccordionPanel
                accordionExpanded={accordionExpanded}
                handleAccordionChange={handleAccordionChange}
                panelName="applepayPanel"
                panelText="Apple Pay"
                panelIcon="apple-pay-logo.svg"
                domain={domain}
              >
                <PaymentMethodApplePay aboutToPayMessageEnabled={aboutToPayMessageEnabled} currencySymbol={currencySymbol} isAvailable={checkApplePaySupport()} amountTotal={amount.total} domain={domain} applePayRef={methods.applePay} paymentData={paymentData} setFailedReceipt={setFailedReceipt} setSuccessReceipt={setSuccessReceipt} />
              </PaymentMethodAccordionPanel>
            }
          </div>
        );
      }}
    </IllionCyberSource>
  )
}


interface ApplePayWindow extends Window {
  ApplePaySession?: any;
}

declare let window: ApplePayWindow;

declare let ApplePaySession: any;

function checkApplePaySupport(): boolean {
  if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
    return true
  }
  return false
}
