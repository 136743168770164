import React, { Suspense } from "react";
// MUI
import { Container, Skeleton, Stack } from "@mui/material";
// Context
import { MyApp } from "./context/AppContext";
import { AppConstants } from "./context/AppConstants";
import VerifyRp, { VerifyErrorCallback, VerifyRpLabels } from "./components/default/body/VerifyRp";
import { VerifyPasscodeDto } from "./api/dto/VerifyRp";
// Body Components
import ErrorDetails from "./components/default/body/ErrorDetails";
import NotFoundMessage from "./components/default/body/NotFoundMessage";
import OptOutSuccess from "./components/default/body/OptOutSuccess";
import Message from "./components/default/body/Message";
import SuccessReceipt, { SuccessReceiptDto } from "./components/default/body/SuccessReceipt";
import FailedReceipt, { FailedReceiptDto } from "./components/default/body/FailedReceipt";
import { VariablePayment } from "./components/default/body/VariablePayment";
// Payment Components
import { PaymentBrainTree } from "./components/default/payment/braintree/PaymentBrainTree";
import PaymentCyberSource from "./components/default/payment/cybersource/PaymentCyberSource";
import ProcessingBackDrop, { defaultProcessingState, ProcessingBackDropProps } from "./components/default/payment/ProcessingBackDrop";

function App() {
  // Destruct state
  const {
    labels,
    payment,
    ready,
    error,
    preHeaderConfig,
    headerConfig,
    footerConfig,
    paymentDetailsConfig,
    paymentConfig,
    providerLogo
  } = MyApp.state;

  // Variable payment state
  const [variablePayment, setVariablePayment] = React.useState(false);

  // Verify RP flags.
  const [verifyDob, setDobRequired] = React.useState(false);
  const [verifyPostcode, setPostcodeRequired] = React.useState(false);
  const [verifyRequired, setVerifyRequired] = React.useState(false);

  const amount = payment?.amount;
  const [paymentAmount, setPaymentAmount] = React.useState(amount);
  React.useEffect(() => setPaymentAmount(amount), [amount]);

  const [processing, setProcessing] = React.useState<ProcessingBackDropProps>(defaultProcessingState);

  // Note some of these error messages are partial messages, so the
  // main chunk of wording what might come back in an error message we
  // then want to check against
  const tokenNotFoundError = "URL token not found";
  const initBadRequestError = "ApiDataError"

  // Handle variable payment button click
  const handleVariablePayment = (amount: number) => {
    setPaymentAmount(amount);
    setVariablePayment(false);
  };

  // Error Messages
  const havePaidError = "You have already paid your balance";

  const isErrorAlreadyPaid = (error: string) => {
    return (error.includes(havePaidError))
  }
  const isErrorTokenURLRelated = (error: string) => {
    // Yeah checking error strings for errors that can happen 
    // when the URL/token is invalid/not found is not ideal, but 
    // until we can get some error codes into the system which includes
    // other services, it's the best I can think of with 
    // current time frames at play.
    return (
      error.includes(initBadRequestError) ||
      error.includes(AppConstants.NoTokenError) ||
      error.includes(tokenNotFoundError)
    )
  }

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleOptOut = async () => {
    setProcessing({ isProcessing: true, content: "Processing Opt-Out...", fadeInDur: 250 });
    // What the heck is this sleep() on the next line you might be asking? the "OptOut" can
    // sometimes complete very quickly, making the Processing spinner flash in/out quickly,
    // adding the sleep will make sure it shows for at least the sleep duration if the OptOut"
    // completes sooner.
    const [cardOptOutResponse] = await Promise.all([MyApp.cardOptOut(), sleep(2000)]);
    setProcessing({ isProcessing: false, content: "", fadeInDur: 250 });

    if (cardOptOutResponse !== undefined) {
      if (cardOptOutResponse.error) {
        MyApp.setError(cardOptOutResponse.error);
      }
      else {
        setSuccessOptOut(true);
      }
    }
    else {
      setSuccessOptOut(false);
    }
  };

  const [successReceipt, setSuccessReceipt] = React.useState<SuccessReceiptDto>();
  const [successOptOut, setSuccessOptOut] = React.useState<boolean>(false);
  const [failedReceipt, setFailedReceipt] = React.useState<FailedReceiptDto>();

  const [resourcesReady, setResourcesReady] = React.useState(false);
  const [linkReady, setLinkReady] = React.useState(false);

  React.useEffect(() => {
    // Resources and link data loaded, All ready to process
    if (resourcesReady && linkReady) {
      MyApp.setReady();
    }
  }, [resourcesReady, linkReady]);

  React.useEffect(() => {
    // Fetch the static resource file for the current domain
    //---------------------------------------------------------
    MyApp.loadStaticResource().then((resource) => {
      if (resource == null) return;
      // Update payment data
      MyApp.updateResource(resource);
      setResourcesReady(true);
    }).catch((reason) => {
      MyApp.setError(`${reason} (Resource Initialization)`);
    });

    // Bail out early if no token was found
    if (MyApp.token == null) {
      return;
    }

    // Fetch the WebPay payment link data
    //---------------------------------------------------------
    MyApp.queryToken().then((paymentLink) => {
      if (paymentLink == null) return;

      if (paymentLink.error || !paymentLink.data) {
        MyApp.setError(paymentLink.error);
      } else {
        // Update static resources and payment data
        setVariablePayment(paymentLink.data.site.variablePaymentSettings?.isPartialPaymentEnabled ?? false);

        // Check whether Verify RP is required.
        setDobRequired(paymentLink.data.verifyByDateOfBirth);
        setPostcodeRequired(paymentLink.data.verifyByPostcode);
        setVerifyRequired(paymentLink.data.isVerifyRpenabled);

        // Update payment data
        MyApp.updatePaymentData(paymentLink.data);
        setLinkReady(true)
      }
    }).catch((reason) => {
      MyApp.setError(`${reason} (Link Initialization)`);
    });
  }, []);

  const performVerification = (
    passcodes: VerifyPasscodeDto[],
    errorCallback: VerifyErrorCallback
  ) => {
    MyApp.verify(passcodes)
      .then((response) => {
        // Unknown(?) issue with the response.
        if (!response) {
          setVerifyRequired(true);
          errorCallback("An unknown error occurred", false);
        }

        // Verification failed. Present the error to the consumer.
        else if (response.error) {
          setVerifyRequired(true);
          errorCallback(response.error, response.maxAttemptsReached ?? false);
        }

        // Consumer verified. We should have access to the consumer's details.
        else {
          return MyApp.queryToken(response.token);
        }
      })
      .then((queryResponse) => {
        if (queryResponse?.data) {
          MyApp.updatePaymentData(queryResponse.data);
          setVerifyRequired(false);
        }
      });
  };

  let verifyRpLabels: VerifyRpLabels = {};
  if (labels) {
    verifyRpLabels = {
      postcodeMessage: labels.verifyRp.postcodeMessage,
      dobMessage: labels.verifyRp.dobMessage,
      inputDay: labels.verifyRp.day,
      inputMonth: labels.verifyRp.month,
      inputYear: labels.verifyRp.year,
      inputPostcode: labels.verifyRp.postcode,
      buttonSubmit: labels.verifyRp.button,
    };
  }

  const verifyView = (
    <React.Fragment>
      <VerifyRp
        postcode={verifyPostcode}
        dob={verifyDob}
        labels={verifyRpLabels}
        onSubmit={performVerification}
      />
    </React.Fragment>
  );

  const variablePaymentView = React.useMemo(() => !error && ready && variablePayment && payment?.amount
    ? <VariablePayment currencySymbol={payment.site.currencySymbol ?? "$"} amount={payment.amount} labels={labels} variablePaymentSettings={payment?.site.variablePaymentSettings} onReady={handleVariablePayment} />
    : undefined, [labels, error, ready, variablePayment, payment]);

  const PaymentProviderMethods = React.useMemo(() => {
    if (!ready || verifyRequired || payment == null || paymentAmount == null || paymentAmount <= 0) return undefined;

    var paymentProviderOption = paymentConfig?.paymentProvider?.toLowerCase();

    return (
      <>
        {paymentProviderOption === "braintree" ? (
          <PaymentBrainTree
            enabledPaymentMethods={paymentConfig!.enabledPaymentMethods}
            aboutToPayMessageEnabled={paymentConfig!.aboutToPayMessageEnabled}
            currencySymbol={payment.site.currencySymbol ?? "$"}
            domain={MyApp.domainPath}
            paymentData={payment}
            paymentAmount={paymentAmount}
            setFailedReceipt={setFailedReceipt}
            setSuccessReceipt={setSuccessReceipt}
          />
        ) : paymentProviderOption === "cybersource" ? (
          <PaymentCyberSource
            enabledPaymentMethods={paymentConfig!.enabledPaymentMethods}
            aboutToPayMessageEnabled={paymentConfig!.aboutToPayMessageEnabled}
            currencySymbol={payment.site.currencySymbol ?? "$"}
            domain={MyApp.domainPath}
            paymentData={payment}
            paymentAmount={paymentAmount}
            setFailedReceipt={setFailedReceipt}
            setSuccessReceipt={setSuccessReceipt}
          />
        ) : (
          <div style={{ textAlign: "center", color: "red", marginBottom: "16px" }}>No payment provider found</div>
        )}
      </>
    );
  }, [ready, paymentAmount, verifyRequired, payment]);

  const componentsFolder = (MyApp.state.components?.CustomComponentsFolder != null && MyApp.state.components.CustomComponentsFolder.trim() !== '')
    ? MyApp.state.components.CustomComponentsFolder
    : 'default';

  const PreHeader = React.lazy(() => import(`./components/${componentsFolder}/header/PreHeader`).catch(() => import('./components/default/header/PreHeader')));
  const HeaderMain = React.lazy(() => import(`./components/${componentsFolder}/header/Header`).catch(() => import('./components/default/header/Header')));
  const FooterMain = React.lazy(() => import(`./components/${componentsFolder}/footer/Footer`).catch(() => import('./components/default/footer/Footer')));
  const PaymentDetails = React.lazy(() => import(`./components/${componentsFolder}/payment/PaymentDetails`).catch(() => import('./components/default/payment/PaymentDetails')));

  return (
    <div className="app">
      {ProcessingBackDrop(processing)}

      {/* PreHeader */
        MyApp.state.components?.PreHeaderEnabled && (
          <Suspense fallback={<Skeleton variant="rectangular" height={20} />}>
            <PreHeader config={preHeaderConfig} extra="" />
          </Suspense>
        )
      }

      {/* MainHeader */
        MyApp.state.components?.HeaderEnabled && (
          <Suspense fallback={<Skeleton variant="rectangular" height={20} />}>
            <HeaderMain logo={headerConfig?.logo} title={headerConfig?.title} isReady={ready || !!error} extra="" />
          </Suspense>
        )
      }

      <Container component="main" className="main">
        <Stack className="main-stack" spacing={1}>
          {!error && ready && verifyRequired ? (
            verifyView
          ) : !error && ready && successOptOut ? (
            <OptOutSuccess />
          ) : !error && ready && successReceipt ? (
            <SuccessReceipt currencySymbol={payment!.site.currencySymbol ?? "$"} receipt={successReceipt} labels={labels?.receipt} optOutHandler={handleOptOut} />
          ) : !error && ready && failedReceipt ? (
            <FailedReceipt
              receipt={failedReceipt}
              labels={labels?.receipt}
              onRetry={() => setFailedReceipt(undefined)}
            />
          ) : !error && ready && payment && variablePayment ? (
            variablePaymentView
          ) : !error && ready && payment ? (
            <React.Fragment>
              {/* Displays the account and amount to be paid */}
              <Suspense fallback={<Skeleton variant="rectangular" height={20} />}>
                <PaymentDetails
                  payment={payment}
                  labels={labels}
                  config={paymentDetailsConfig}
                  providerLogoPath={providerLogo}
                  domain={MyApp.domainPath}
                  amount={paymentAmount ?? 0}
                />
              </Suspense>

              {/* Displays payment options/methods available */}
              {PaymentProviderMethods}
            </React.Fragment>
          ) : error && isErrorAlreadyPaid(error) ? (
            <Message title="Thank you!" msg={error} />
          ) : error && isErrorTokenURLRelated(error) ? (
            <Message title="Oops. We can't find that page" msg={<NotFoundMessage />} />
          ) : error ? (
            <ErrorDetails errorLabel="An error occurred" error={error} />
          ) : (
            <Stack className="app-skeleton" paddingTop={4} spacing={2}>
              <Skeleton variant="rectangular" height={75} />
              <Skeleton variant="text" sx={{ fontSize: "23px" }} />
              <Skeleton variant="rectangular" height={164} />
              <Skeleton variant="rounded" height={420} />
            </Stack>
          )}
        </Stack>
      </Container>

      {/* Footer */
        MyApp.state.components?.FooterEnabled && (
          <Suspense fallback={<Skeleton variant="rectangular" height={20} />}>
            <FooterMain config={footerConfig} />
          </Suspense>
        )
      }

    </div>
  );
}

export default App;