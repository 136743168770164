import React from 'react';
import OptOut from './OptOut';

export type SuccessReceiptLabels = {
   successTitle?: string;
   successMessage?: string;
   totalCharged?: string;
   accountNumber?: string;
   paymentReference?: string;
   termsAndConditionsLink: string;
}

export type SuccessReceiptDto = {
   reference: string;
   account: string;
   amount: number;
   cardRegistered: boolean;
}

export type SuccessReceiptProperties = {
   receipt: SuccessReceiptDto;
   labels: SuccessReceiptLabels;
   currencySymbol: string,
   optOutHandler: () => Promise<void>;
}

export default function SuccessReceipt(props: SuccessReceiptProperties) {
   const { receipt, labels, currencySymbol, optOutHandler } = props;

   React.useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   return (
      <React.Fragment>
         <div className="Receipt-container">
            <div className="flex-col heading-container">
               <span className="title">{labels?.successTitle}</span>
               <span className="value">{labels?.successMessage}</span>
            </div>
            <hr />
            <div className="receipt-details-container">
               <div className="flex-row account-total-container">
                  <label className="title">{labels?.totalCharged}</label>
                  <label className="value">{currencySymbol}{receipt.amount.toFixed(2)}</label>
               </div>
               <div className="flex-row account-number-container">
                  <label className="title">{labels?.accountNumber}</label>
                  <label className="value">{receipt.account}</label>
               </div>
               <div className="flex-row account-payment-reference">
                  <label className="title">{labels?.paymentReference}</label>
                  <label className="value">{receipt.reference}</label>
               </div>
            </div>
         </div>
         <div className='optout-section'>
            {receipt.cardRegistered && <OptOut link={labels!.termsAndConditionsLink} optOutHandler={optOutHandler} />}
         </div>
      </React.Fragment>
   );
}