import { useState, useEffect } from 'react';
import { SharedUtils } from '../../utils/SharedUtils';

export type cssLoaderProps = {
  href: string,
  children: any
}

export default function CSSLoader(props: cssLoaderProps) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = props.href;
    link.onload = () => {
      setIsLoaded(true);
      SharedUtils.debugLog(`custom CSS: '${props.href}' loaded`);
    }
    link.onerror = () => {
      document.head.removeChild(link);
      setIsLoaded(true);
      SharedUtils.debugLog(`custom CSS: '${props.href}' not found`);
    }
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [props.href]);

  return isLoaded ? props.children : null;
};